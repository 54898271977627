import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Router } from "@reach/router"

import SEO from "../components/SEO/Seo"
import SideNavigation from '../components/Account/SideNavigation'
import Dashboard from "../components/Account/Dashboard"
import Orders from "../components/Account/Orders"
import ResetPassword from "../components/Account/ResetPassword"
import Membership from '../components/Account/Membership'

import useAuth from "../hooks/useAuth"

import { FaSpinner } from 'react-icons/fa'

const App = ({ location }) => {
	const { isAuthenticated, loadingUser } = useAuth()
	const redirect = location.pathname.split('/').pop()

	useEffect( () => {
		if ( !isAuthenticated && !loadingUser ) {
			navigate( '/login', { state: { redirect } } );
		}
	}, [ isAuthenticated, redirect, loadingUser ] );

	return (
		<div className="account-page">

			<SEO title="Account" description="Access orders, membership details and the dashboard for the account area of dream on." />
			<div className="account-page__wrapper wrapper">

				{ isAuthenticated && !loadingUser ? (
					<>
						<div className="account-page__navigation">
							<SideNavigation />
						</div>

						<div className="account-page__main">
							<Router basepath="/account">
								<Membership path="/membership" />
								<Orders path="/orders" />
								<ResetPassword path="/change-password"/>
								<Dashboard default/>
							</Router>
						</div>
					</>
				) : (
					<div className="account-page__loading-container">
						<FaSpinner className="loader" />
						<h3 className="account-page__loading-text">Loading</h3>
					</div>
				) }
			</div>
		</div>
	)
}
export default App