import React from 'react'

import useAuth from '../../hooks/useAuth'

import StaffDiscount from '../../images/svgs/staff-discount.svg'
import PurpleCardHolder from '../../images/svgs/purple-card-holder.svg'

export default function Orders() {

	const { state } = useAuth()

	return (
		<>
			<div className="account-details">
				<div className="account-details__header">
					<h1 className="account-details__heading">Membership</h1>
				</div>
				<div className="account-details__section">
					<div className="account-details__section-container">
						{ state.user.staffDiscount &&
							<>
								<p className="account-details__section-header">
									As a special member of the team you will receive a 50% discount on all non-sale items and 25% on sale items. Excludes gift vouchers and makeover days. Thank you for all that you do.
								</p>
								<StaffDiscount className="account-details__membership-image" />
							</>
						}
						{ state.user.purpleCardDiscount &&
							<>
								<p className="account-details__section-header">
									As a valued customer and purple card holder you will receive a 10% discount on all non-sale items (excl gift vouchers and makeover days). Thanks for your support.
								</p>
								<PurpleCardHolder className="account-details__membership-image" />
							</>
						}
					</div>
				</div>
			</div>
		</>
	)
}