import React from 'react'

import { Link } from 'gatsby'

import AvatarIcon from '../../images/svgs/avatar-icon.svg'

import useAuth from '../../hooks/useAuth'

export default function SideNavigation() {

	const { state, logout } = useAuth()

	const handleLogout = ( event ) => {
		event.preventDefault()
		logout()
	};

	return (
		<div className="account-navigation">
			<div className="account-navigation__container">
				<div className="account-navigation__greeting">
					<div className="account-navigation__names">
						<span className="account-navigation__name account-navigation__name--first">{ state.user.firstName }</span>
						<span className="account-navigation__name account-navigation__name--last">{ state.user.lastName }</span>
					</div>
					<AvatarIcon className="account-navigation__avatar-icon" />
				</div>
				
				<div className="account-navigation__links-container">
					{ ( state.user.staffDiscount || state.user.purpleCardDiscount ) &&
						<Link to="/account/membership" className="account-navigation__link" activeClassName="active">
							Membership
						</Link>
					}
					<Link to="/account/orders" className="account-navigation__link" activeClassName="active">
						Order History
					</Link>
					<Link to="/account" className="account-navigation__link" activeClassName="active">
						Profile
					</Link>
					<Link to="/account/change-password" className="account-navigation__link" activeClassName="active">
						Change password
					</Link>
					<a href="#logout" className="account-navigation__link" onClick={ e => handleLogout(e) }>
						Logout
					</a>
				</div>
			</div>
		</div>
	)
}