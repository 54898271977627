import React, { useState } from 'react'
import PropTypes from "prop-types"
import moment from 'moment'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { formatPrice } from '../../helpers/currency'
import { getFluidImage } from '../../helpers'

import { FiPlus } from "react-icons/fi"
import Chevron from '../../images/svgs/chevron.svg'
import MakeoverDayIllustration from '../../images/svgs/makeover-day.svg'
import SpecialGiftIllutration from '../../images/svgs/special-message.svg'
export default function OrdersListItem( props ) {

	const order = props.order
	const maxWidth = 500

	const [ active, setActive ] = useState( false )

	const renderProductImages = ( products ) => {
		const updatedProducts = products.map( product => {
			product.images[0].imageFile =  { childImageSharp : { fluid : getFluidImage( product, maxWidth ) } }
			return product
		} )

		return updatedProducts
	}

	const createProductDetails = () => {
		let cart = {
			products: [],
			makeoverDays: [],
			giftVouchers: []
		}

		const updatedProducts = renderProductImages( order.products )

		order.productQuantity.forEach( ( product, index ) => {
			let foundProduct
			let productDetails

			if ( product.makeoverDay ) {
				foundProduct = order.makeoverDays.find( correctProduct => correctProduct.id === product.id )

				if ( foundProduct ) {
					productDetails = {
						id: foundProduct.id,
						name: foundProduct.name,
						price: foundProduct.price,
						date: foundProduct.Date,
						quantityBought: product.quantity
					}
		
					cart.makeoverDays.push( productDetails );
				}

			} else if ( product.giftCard ) {

				productDetails = {
					id: index,
					quantityBought: product.quantity,
					price: product.price,
					name: product.id
				}

				cart.giftVouchers.push( productDetails )
			} else {
				foundProduct = updatedProducts.find( correctProduct => correctProduct.id === product.id );

				if ( foundProduct ) {
					productDetails = {
						id: foundProduct.id,
						name: foundProduct.name,
						images: foundProduct.images,
						price: foundProduct.price,
						size: product.size,
						colourOption: product.colourOption,
						quantityBought: product.quantity,
						slug: foundProduct.slug
					}
		
					cart.products.push( productDetails );
				}
			}
		} )

		return cart;
	}

	const products = createProductDetails()
	const noProductsAvailable = !products.products.length && !products.giftVouchers.length && !products.makeoverDays.length ? true : false
	const orderTotalPrice = order.giftVoucherRedemption ? order.giftVoucherRedemption + order.totalPrice : order.totalPrice

	return (
		<div className={`orders__item ${ active ? 'active' : '' }`} key={ order.id }>

			<div
				className="orders__header"
				role="button"
				tabIndex={ 0 }
				onClick={ () => setActive( !active ) }
				onKeyDown={ () => setActive( !active ) }
				>
				<div className="orders__header-info">
					<span className="orders__label">Order No</span>
					<span className="orders__info">1728{ order.id }</span>
				</div>
				<div className="orders__header-info">
					<span className="orders__label">Status</span>
					<span className="orders__info">{ order.status === 'Shipped' ? 'Posted' : order.status }</span>
				</div>
				<div className="orders__header-info">
					<span className="orders__label">Total</span>
					<span className="orders__info">{ formatPrice( orderTotalPrice ) }</span>
				</div>
				<div className="orders__header-info">
					<span className="orders__label">Date</span>
					<span className="orders__info">{ moment( order.created_at ).format( 'DD/MM/YYYY' ) }</span>
				</div>

				<div className="orders__header-icon-container">
					{ !active ? (
						<FiPlus className="orders__header-icon" />
					) : (
						<Chevron className="orders__header-icon orders__header-icon--chevron" />
					)}
				</div>
			</div>

			<div className={`orders__body ${ active ? 'active' : '' }`}>
				<div className="orders__body-wrapper">

					<div className="orders__products-list">
						{ products.makeoverDays.map( ( product, i ) => (
							<Link to="/makeover-days" className="orders__product" key={ i }>
								<div className="orders__product-thumbnail-container">
									<MakeoverDayIllustration className="orders__product-thumbnail orders__product-thumbnail--illustration" />
								</div>
								<div className="orders__product-info">
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											Makeover Day
										</div>
										
										<div className="orders__product-text orders__product-text--alt">
											{ moment( product.date ).format("Do MMM YYYY") }
										</div>
									</div>
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											x{ product.quantityBought }
										</div>
									</div>
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											{ formatPrice( product.price ) }
										</div>
									</div>
								</div>
							</Link>
						) ) }
						{ products.products.map( ( product, i ) => (
							<Link to={ `/products/${ product.slug }` } className="orders__product" key={ i }>
								<div className="orders__product-thumbnail-container">
									<Img className="orders__product-thumbnail" fluid={ product.images[0].imageFile.childImageSharp.fluid } />
								</div>
								<div className="orders__product-info">
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											{ product.name }
										</div>
										{ product.colourOption &&
											<div className="orders__product-text orders__product-text--alt">
												{ product.colourOption }
											</div>
										}
										<div className="orders__product-text orders__product-text--alt">
											Size: { product.size }
										</div>
									</div>
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											x{ product.quantityBought }
										</div>
									</div>
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											{ formatPrice(product.price) }
										</div>
									</div>
								</div>
							</Link>
						) ) }
						{ products.giftVouchers.map( ( product, i ) => (
							<Link to="/products/gift-vouchers" className="orders__product" key={ i }>
								<div className="orders__product-thumbnail-container">
									<SpecialGiftIllutration className="orders__product-thumbnail orders__product-thumbnail--illustration" />
								</div>
								<div className="orders__product-info">
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											{ product.name }
										</div>
										
										<div className="orders__product-text orders__product-text--alt">
											Value: { formatPrice( product.price ) }
										</div>
									</div>
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											x{ product.quantityBought }
										</div>
									</div>
									<div className="orders__product-info-container">
										<div className="orders__product-text">
											{ formatPrice( product.price ) }
										</div>
									</div>
								</div>
							</Link>
						) ) }
						{ noProductsAvailable &&
							<div className="orders__no-products-container">
								<div className="orders__no-products-message">
									Product information is currently unavailable or the products are no longer online for this order.
								</div>
							</div>
						}
						<div className="orders__shipping-container">
							<div className="orders__shipping">
								Postage: { order.postagePrice ? formatPrice( order.postagePrice ) : order.postageName }
							</div>
						</div>
					</div>

				</div>
			</div>

		</div>
	)
}

OrdersListItem.propTypes = {
	order: PropTypes.object
}
