import React, { useState } from 'react'

import useAuth from '../../hooks/useAuth'

import Illustrations from '../../components/Illustrations/Illustrations'

import Chevron from '../../images/svgs/chevron.svg';

export default function ResetPassword() {

	const { state, updateProfile, login } = useAuth()

	const [ oldPassword, setOldPassword ] = useState("");
	const [ password, setPassword ] = useState("");
	const [ confirmPassword, setConfirmPassword ] = useState("");
	const [ passwordMatchError, setPasswordMatchError ] = useState(false);
	const [ oldPasswordError, setOldPasswordError ] = useState(false);
	const [ identifier ] = useState( state.user.email );

	const [ formSubmitted, setFormSubmitted ] = useState(false);

	const handleSubmit = async ( event ) => {
		event.preventDefault()

		// checks it logs in 
		try {
			await login( { identifier, password: oldPassword } ) 
		} catch( e ) {
			console.log("Error occurred during authentication")
			setOldPasswordError( true )
			return
		}
		if ( password !== confirmPassword ) {
			setPasswordMatchError( true )
			return;
		} else {
			setPasswordMatchError( false )
		}

		try {
			await updateProfile( { password } );
			setFormSubmitted( true )
		} catch ( error ) {
			console.log( error )
		}
	}

	return (
		<div className="account-details">
			<div className="account-details__header">
				<h1 className="account-details__heading">Change password</h1>
			</div>
			<div className="account-details__section">
				<h3 className="account-details__section-header">
					Reset your password
					<Chevron className="account-details__section-header-icon" />
				</h3>
				<div className="account-details__section-container">
					{ !formSubmitted ? (
						<form  className="form" onSubmit={ handleSubmit }>
							<div className={`form__input-container ${ oldPasswordError ? 'error' : '' }`}>
								<label className="form__label" htmlFor="oldPassword">Old Password</label>
								<input className="form__input" type="password" name="oldPassword" id="oldPassword" value={ oldPassword } placeholder="Old password" onChange={ e => { setOldPassword( e.target.value ) } } required/>
								<span className="form__input-error">Your Password is incorrect</span>
							</div>

							<div className="form__input-container">
								<label className="form__label" htmlFor="password">New Password</label>
								<input className="form__input" type="password" name="password" id="password" value={ password } placeholder="New password" onChange={ e => { setPassword( e.target.value ) } } required/>
							</div>
							<div className={`form__input-container ${ passwordMatchError ? 'error' : '' }`}>
								<label className="form__label" htmlFor="confirmPassword">Confirm New Password</label>
								<input className="form__input" type="password" name="confirmPassword" id="confirmPassword" value={ confirmPassword } placeholder="Confirm new password" onChange={ e => { setConfirmPassword( e.target.value ) } } required/>
								<span className="form__input-error">Password confirmation does not match password!</span>
							</div>

							<div className="form__button-container">
								<button className="form__submit button button--primary" type="submit">Change Password</button>
							</div>
						</form>
					): (
						<p className="form-page__success">
							Your password has been reset
							<Illustrations class="form-page__success-image" illustration={ 'Family' } />
						</p>
					) }
				</div>
			</div>
		</div>
	)
}

