import React, { useState, useRef } from 'react'

import useAuth from '../../hooks/useAuth'

import Chevron from '../../images/svgs/chevron.svg';

export default function Dashboard() {

	const { state, updateProfile } = useAuth()

	const form = useRef();

	const [ editMode, setEdit ] = useState( false );
	const [ firstName, setFirstName ] = useState( state.user.firstName );
	const [ lastName, setLastName ] = useState( state.user.lastName );
	const [ addressLine1, setAddressLine1 ] = useState( state.user.addressLine1 );
	const [ addressLine2, setAddressLine2 ] = useState( state.user.addressLine2 );
	const [ city, setCity ] = useState( state.user.city );
	const [ postcode, setPostcode ] = useState( state.user.postcode );
	const [ county, setCounty ] = useState( state.user.county );

	const handleFormSubmit = async ( event ) => {
		event.preventDefault();
		form.current.reportValidity();

		const profileData = {
			firstName,
			lastName,
			addressLine1,
			addressLine2,
			city,
			postcode,
			county
		}
		
		try {
			await updateProfile( profileData );
		} catch ( error ) {
			console.log( error )
		}

		setEdit(!editMode)
	}

	return (
		<div className="account-details">
			<div className="account-details__header">
				<h1 className="account-details__heading">Profile</h1>
			</div>
			<div className="account-details__section">
				<h3 className="account-details__section-header">
					Name &amp; Address
					<Chevron className="account-details__section-header-icon" />
				</h3>
				<div className="account-details__section-container">
					{ !editMode ? (
						<>
							<div className="account-details__text">{ state.user.firstName } { state.user.lastName }</div>
							<div className="account-details__text">{ state.user.addressLine1 }</div>
							<div className="account-details__text">{ state.user.addressLine2 }</div>
							<div className="account-details__text">{ state.user.city }</div>
							<div className="account-details__text">{ state.user.county }</div>
							<div className="account-details__text">{ state.user.postcode }</div>
							<div className="account-details__text">{ state.user.country }</div>
							{ !editMode &&
								<div className="form__button-container">
									<button className="account-details__link" onClick={() => setEdit(!editMode)}>Edit details</button>
								</div>
							}
						</>
					) : (
						<form className="form" onSubmit={ handleFormSubmit } ref={ form }>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="firstName">First Name</label>
								<input className="form__input" type="text" name="firstName" id="firstName" value={ firstName } onChange={ e => { setFirstName( e.target.value ) } } required />
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="lastName">Last Name</label>
								<input className="form__input" type="text" name="lastName" id="lastName" value={ lastName } onChange={ e => { setLastName( e.target.value ) } } required/>
							</div>

							<div className="form__input-container">
								<label className="form__label"  htmlFor="addressLine1">House No/Name</label>
								<input className="form__input" type="text" name="addressLine1" id="addressLine1" value={ addressLine1 } onChange={ e => { setAddressLine1( e.target.value ) } } required/>
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="addressLine2">Address 2</label>
								<input className="form__input" type="text" name="addressLine2" id="addressLine2" value={ addressLine2 } onChange={ e => { setAddressLine2( e.target.value ) } }/>
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="city">City</label>
								<input className="form__input" type="text" name="city" id="city" value={ city } onChange={ e => { setCity( e.target.value ) } } required/>
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="county">County</label>
								<input className="form__input" type="text" name="county" id="county" value={ county } onChange={ e => { setCounty( e.target.value ) } } required/>
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="postcode">Postcode</label>
								<input className="form__input" type="text" name="postcode" id="postcode" value={ postcode } onChange={ e => { setPostcode( e.target.value ) } } required/>
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="country">Country</label>
								<input className="form__input" type="text" name="country" id="country" placeholder="United Kingdom" disabled />
							</div>
							<div className="form__button-container">
								<button className="form__submit button button--primary button--no-margin" type="submit">Save</button>
							</div>
						</form>
					) }
				</div>
			</div>

			<div className="account-details__section">
				<h3 className="account-details__section-header">
					Email
					<Chevron className="account-details__section-header-icon" />
				</h3>
				<div className="account-details__section-container">
					<div className="account-details__text">{ state.user.email }</div>
				</div>
			</div>
			
		</div>
	)
}