import { API_URL } from './constants'

export const aspectRatio = ( product ) => {
	return product.images[0].width / product.images[0].height
}

export const srcSet = ( product ) => {
	let srcset = []

	for ( let key in product.images[0].formats ) {
		const srcString = `${ product.images[0].formats[ key ].url } ${ product.images[0].formats[ key ].width }w`
		const src = process.env.NODE_ENV === 'production' ? srcString : `${ API_URL }${ srcString }`
		srcset.push( src )
	}

	return srcset.join()
}

export const getFluidImage = ( product, maxWidth ) => {
	const result = {}
	result.srcSet = srcSet( product )
	result.aspectRatio = aspectRatio( product )
	result.src = process.env.NODE_ENV === 'production' ? product.images[0].url : `${ API_URL }${ product.images[0].url }`
	result.sizes = `(max-width: ${ maxWidth }px) 100vw, ${ maxWidth }px`

	return result
}